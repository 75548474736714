// Personal website and portfolio //
// 2022                           //
// Built by Mark Lisanti          //
// https://github.com/marklasagne //

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const PillFilter = ({ tag, onClick }) => {
  const [isActive, setActive] = useState(false);

  useEffect(() => {
    // Check if the tag is 'Featured' and set it as active on load
    if (tag === 'Featured') {
      setActive(true);
    }
  }, [tag]);

  const toggleClass = () => {
    setActive(!isActive);
  };

  return (
    <>
      <PillButton className={isActive ? 'active' : null} onClick={() => { toggleClass(); onClick(); }}>{tag}</PillButton>
    </>
  );
};

const PillButton = styled.div`
  color: #808080;
  font-size: 0.8rem;
  border: 2px solid;
  padding: 0.625rem 1.25rem;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 0.25rem 0.125rem;
  cursor: pointer;
  border-radius: 30px;
  &.active, &:hover {
    color: #ffffff;
    background: #000000;
    border: 2px solid #000000;
  }
`;

export default PillFilter;

