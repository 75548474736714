// Personal website and portfolio //
// 2023                           //
// Built by Mark Lisanti          //
// https://github.com/marklasagne //

// GLTF model Auto-generated by: https://github.com/pmndrs/gltfjsx
// aniamted and lighting handled throug shaders

import React, { useRef, useEffect, useState, useMemo } from 'react';
import { useFrame, useThree } from '@react-three/fiber';
import { useGLTF } from '@react-three/drei';
import meltingVertexShader from './shaders/meltingVertexShader';
import staticVertexShader from './shaders/staticVertexShader';
import headShader from './shaders/headShader';
import hairShader from './shaders/hairShader';
import ringShader from './shaders/ringShader';

const Model = ({ keyLightData, ...props }) => {
  const [scrollY, setScrollY] = useState(0);
  const [changeVal, setchangeVal] = useState(0);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const { viewport } = useThree();
  const { nodes, materials } = useGLTF('/portrait.glb');

  const head = useRef();
  const group = useRef();
  const eyes = useRef();

  let x_middle = viewport.width / 2;
  let y_middle = viewport.height / 2;

  useGLTF.preload('/portrait.glb');
  // listen and capture scroll y value and the mouse movement
  useEffect(() => {
    const handleMouseMove = (event) => {
      setMousePosition({ x: event.clientX, y: event.clientY });
    };
    const handleScroll = () => {
      const scrollPercentage = (window.scrollY / window.innerHeight) * 100;
      const limitedScrollY = Math.min(scrollPercentage, 23);
      setScrollY(limitedScrollY);
    };
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('mousemove', handleMouseMove);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  useFrame((state) => {
    // update uniforms
    const { clock } = state;
    const currentScrollY = scrollY;

    head.current.material.uniforms.uTime.value = clock.getElapsedTime();
    if (head.current.material.uniforms.scrollY.value !== currentScrollY) {
      head.current.material.uniforms.scrollY.value = currentScrollY;
      const targetValue = 0.7;
      const easingFactor = 0.05;
      const delta = targetValue - changeVal;
      setchangeVal(changeVal + delta * easingFactor);
    } else {
      if (changeVal > 0.0) {
          const oscillation = Math.sin((changeVal - 0.2) * 5);
          let tempVal = changeVal - 0.01 * oscillation;
          setchangeVal(tempVal);
  
          head.current.material.uniforms.changeVal.value = tempVal;
      }
    }

    // normalize mouse position
    const normalizedMouseX = (mousePosition.x / window.outerWidth) * 2 - 1;
    const normalizedMouseY = -(mousePosition.y / window.outerHeight) * 2 + 1;

    // animate model
    //const group_rotation_x = group.current.rotation.x;
    //const group_rotation_y = group.current.rotation.y;
    //const step = 0.00001 * 500;
    //const stepEyes = 0.00001 * 100;

    if (normalizedMouseX > -1 && normalizedMouseX < 1 && normalizedMouseY > -1 && normalizedMouseY < 1) {
      const targetX = (normalizedMouseX * x_middle) / 30;
      const targetY = (normalizedMouseY * y_middle) / 30;
      group.current.rotation.x += (-targetY - group.current.rotation.x) * 0.1;
      eyes.current.rotation.x += (-targetY / 4 - eyes.current.rotation.x) * 0.1;
      group.current.rotation.y += (targetX - group.current.rotation.y) * 0.1;
      eyes.current.rotation.y += (targetX / 4 - eyes.current.rotation.y) * 0.1;
    } else {
      group.current.rotation.x += (0 - group.current.rotation.x) * 0.1;
      eyes.current.rotation.x += (0 - eyes.current.rotation.x) * 0.1;
      group.current.rotation.y += (0 - group.current.rotation.y) * 0.1;
      eyes.current.rotation.y += (0 - eyes.current.rotation.y) * 0.1;
    }
  });

  const uniforms = useMemo(() => {
    if (keyLightData.current) {
      return {
        headTexture: {
          value: materials.Head_texture.map,
        },
        keyLightBrightness: {
          value: keyLightData.current.intensity,
        },
        keyLightColor: {
          value: keyLightData.current.color,
        },
        keyLightPosition: {
          value: keyLightData.current.position,
        },
        scrollY: {
          value: 0.0
        },
        changeVal: {
          value: 0.0
        },
        uTime: {
          value: 0.0
        },
      };
    }

  }, [keyLightData.current]);


  return (
    <>
      <group {...props} dispose={null} ref={group}>
        <mesh geometry={nodes.Beanie.geometry} material={materials.Beanie_texture} position={[0, 1.013, -0.113]} rotation={[1.565, 0, 0]} scale={[0.795, 0.739, 0.741]} />
        <group ref={eyes}>
          <mesh geometry={nodes.Left_eye.geometry} material={materials.Left_eye_texture} position={[0.302, 0.355, 0.89]} rotation={[1.666, 0.513, -0.189]} scale={[0.255, 0.301, 0.249]} />
          <mesh geometry={nodes.Right_eye.geometry} material={materials.Right_eye_texture} position={[-0.285, 0.353, 0.888]} rotation={[1.474, 0.513, 0.197]} scale={[0.255, 0.301, 0.249]} />
        </group>
        <mesh geometry={nodes.Nose_ring.geometry} material={nodes.Nose_ring.material} position={[-0.004, -0.071, 1.227]} rotation={[1.564, 0, 0.019]} scale={0.056}>
          <shaderMaterial
            fragmentShader={ringShader}
            vertexShader={staticVertexShader}
            uniforms={uniforms}
          />
        </mesh>
        <mesh geometry={nodes.Head.geometry} material={materials.Head_texture} ref={head}>
          <shaderMaterial
            fragmentShader={headShader}
            vertexShader={meltingVertexShader}
            uniforms={uniforms}
          />
          <mesh geometry={nodes.Curves.geometry} material={nodes.Curves.material}>
            <shaderMaterial transparent opacity={0.5}
              fragmentShader={hairShader}
              vertexShader={meltingVertexShader}
              uniforms={uniforms}
            />
          </mesh>
        </mesh>
      </group>
    </>
  )
};

useGLTF.preload('/portrait.glb');

export default Model;




